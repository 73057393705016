import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import book from "../../images/vision-without-limits.pdf";
import ThankyouStyle from "./Thankyou.module.css";
import Wrapper from "../ui/Wrapper";

const EmailThankYou = () => {
  const { name } = useParams();
  //   console.log(name);
  const cardWrapper = ThankyouStyle.cardWrapper;
  const innerCardWrapper = ThankyouStyle.innerCardWrapper;
  const cardBody = ThankyouStyle.cardBody;

  return (
    <>
      <Wrapper className="contactBody"></Wrapper>

      <Wrapper className={cardWrapper}>
        <Wrapper className={innerCardWrapper}>
          <Wrapper className={cardBody}>
            <h1>
              Hello <b>{name}</b>
            </h1>

            <h2>
              Thank you for providing your email.
              <br />
            </h2>

            <Button
              // style={{ marginTop: "1rem" }}
              variant={"dark"}
              href={book}
            >
              Download Herman's free eBook on Vision With Out Limits
            </Button>

            <br />

            <Button
              style={{
                marginTop: "1rem",
                background: "none",
                border: "none",
                color: "black",
              }}
              href="/"
            >
              Back to Website
            </Button>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </>
  );
};
export default EmailThankYou;
