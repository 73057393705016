/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import HeaderStyle from "./css/SAMobile.module.css";
import SubTitle from "./SubTitle";
// import conferenceVideo from "../../images/highlights/africa-conference-1.mp4";
import thumbnailImage from "../../images/highlights/thumbnail.jpg";

const SADesktop = () => {
  const headerImgBack = HeaderStyle.headerImgBack;
  const gradient = HeaderStyle.gradient;
  const headerRow = HeaderStyle.headerRow;
  const fireBottom = HeaderStyle.fireBottom;
  const bkcolor = { color: "transparent" };
  const headContainer = HeaderStyle.headContainer;
  const headSection1 = HeaderStyle.headSection1;
  const contentH2A = HeaderStyle.contentH2A;
  const videoStyle = HeaderStyle.videoStyle;

  return (
    <>
      <div className={headContainer}>
        <div className={headSection1}>
          <div className={headerImgBack}>
            <div className={gradient}>
              <div className={headerRow}>
                <div style={bkcolor}></div>
              </div>
              <SubTitle />
              <hr />
              <h2 className={contentH2A}>
                The Journey from Trauma to Healing: A Personal Story
              </h2>
              <div style={{ textAlign: "center" }}>
                <iframe
                  className={videoStyle}
                  src="https://www.youtube.com/embed/FZNk2pSJC2M?si=z6sIrTyI9fLUm_BZ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={fireBottom}></div>

      {/* {matchesMinWith665 && <SADesktop />} */}
    </>
  );
};

export default SADesktop;
