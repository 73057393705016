import React from "react";
import "../style.css";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
import NavStyle from "./Nav.module.css";
import useMediaQuery from "../UseMediaQuery";

const Navigation = () => {
  const navBar = NavStyle.navBar;
  const matchesLg = useMediaQuery("(min-width:765px)");
  const bookLink =
    "https://www.amazon.com/Nothing-Almost-Something-Herman-Taylor-ebook/dp/B0CPRNWF38/ref=sr_1_1?crid=3KU6N1Y4IMVGX&keywords=from+nothing+to+almost+something+by+herman+taylor&qid=1702570322&sprefix=from+nothing+to+almost+so%2Caps%2C94&sr=8-1";

  return (
    <>
      <Navbar className={navBar} expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <div style={{ height: "11px" }}></div>
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href={bookLink}>Order Herman's First Book</Nav.Link>
              {/* <Nav.Link href="https://link.clover.com/urlshortener/MjZLQj"> 
                
              </Nav.Link>*/}
              <Nav.Link href="/collect">Ebook</Nav.Link>
              <Nav.Link href="/#tour">24' BAM Tour</Nav.Link>
              <Nav.Link href="/#sheet">Speaker Sheet</Nav.Link>
              <Nav.Link href="/#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navigation;
