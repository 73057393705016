import React from "react";
import FooterStyle from "./footer.module.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const footerDesktop = FooterStyle.footerDesktop;
  return (
    <footer className={footerDesktop} style={{ textTransform: "uppercase" }}>
      COPY RIGHT @ Hermantaylorsvision.com {currentYear} Designing and developed
      by DESIGNINGLORY.COM
    </footer>
  );
};

export default Footer;
