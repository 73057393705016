import React from "react";
import HeaderStyle from "./css/Header.module.css";
import { Button } from "react-bootstrap";
import useMediaQuery from "../UseMediaQuery";
import { Link } from "react-router-dom";

const PurchaseBtn = () => {
  const headerBtn = HeaderStyle.headerBtn;
  const headerEbook = HeaderStyle.headerEbook;
  const tourLink = HeaderStyle.tourLink;
  const headerBtnContainer = HeaderStyle.headerBtnContainer;
  const matchesMinWith665 = useMediaQuery("(min-width:599px)");
  const bookLink =
    "https://www.amazon.com/Nothing-Almost-Something-Herman-Taylor-ebook/dp/B0CPRNWF38/ref=sr_1_1?crid=3KU6N1Y4IMVGX&keywords=from+nothing+to+almost+something+by+herman+taylor&qid=1702570322&sprefix=from+nothing+to+almost+so%2Caps%2C94&sr=8-1";

  return (
    <div className={headerBtnContainer}>
      <Button className={headerBtn}>
        <span>ORDER HERMAN'S NEW BOOK</span>
        <a href={bookLink} className={tourLink}>
          <br />
          "FROM NOTHING {!matchesMinWith665 && <br />} TO ALMOST SOMETHING"
        </a>
        <hr style={{ margin: ".3rem" }} />
        <a className={tourLink} href="/#tour">
          LEARN ABOUT HERMAN'S{!matchesMinWith665 && <br />} 24' BAM TOUR
        </a>
        <hr style={{ margin: ".3rem" }} />
        <a className={tourLink} href="/#sheet">
          VIEW SPEAKER SHEET
        </a>
      </Button>
      <Link to="/collect" className={headerEbook}>
        <span>Give Your Email & receive</span> <br />
        Vision With Out
        {!matchesMinWith665 && <br />} Limits e-Book
      </Link>
    </div>
  );
};

export default PurchaseBtn;
