import Reac from "react";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";
import useMediaQuery from "../../UseMediaQuery";

const SubmitButton = ({ ffIsValid, validated }) => {
  const btn = ContactStyle.btn;
  const location = useLocation();

  let submitForEmail;
  if (location.pathname === "/collect") {
    submitForEmail = true;
  } else {
    submitForEmail = false;
  }

  return (
    <div>
      {!submitForEmail && (
        <Button variant="light" type="submit" value="send" className={btn}>
          SUBMIT
        </Button>
      )}
      {submitForEmail && (
        <Button variant="light" type="submit" value="send" className={btn}>
          Get eBook
        </Button>
      )}
    </div>
  );
};
export default SubmitButton;
