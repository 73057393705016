import React from "react";
import Header from "./Header/Header";
import ComingSoon from "./Coming Soon/ComingSoon";
import comingSoonStyle from "./Coming Soon/css/ComingSoonStyle.module.css";
import Footer from "./Footer/Footer";
import Interview from "./Interview/Interview";
import Contact from "./Contact/Contact";
import Nav from "./ui/Nav";
import useMediaQuery from "./UseMediaQuery";
import SAMobile from "./SupstanceAbuse/SAMobile";

const Screen = () => {
  const marginStyle = comingSoonStyle.marginStyle;
  const marginStyle2 = comingSoonStyle.marginStyle2;
  const matchesMinWith768 = useMediaQuery("(min-width:768px)");

  return (
    <div style={{ maxWidth: "1430px", margin: "0 auto" }} className="p-0">
      {matchesMinWith768 && <Nav />}
      <Header />

      {/* <div className={marginStyle}> */}
      <SAMobile />
      {/* <div className={marginStyle2}> */}
      <ComingSoon />
      <Interview />
      <Contact />
      <Footer />
      {/* </div> */}
    </div>
    // </div>
  );
};

export default Screen;
