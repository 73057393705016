import React from "react";
import HeaderStyle from "./css/SAMobile.module.css";

const SubTitleA = () => {
  const contentH2A = HeaderStyle.contentH2A;
  const headerContent = HeaderStyle.headerContent;

  return (
    <div className={headerContent}>
      <h2 className={contentH2A}>
        Substance Abuse Recovery Advocacy Tour Highlights
      </h2>
    </div>
  );
};

export default SubTitleA;
