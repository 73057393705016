import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Screen from "./components/Screen";
import EmailCollect from "./components/Contact/EmailCollect";
import Thankyou from "./components/Contact/Thankyou";
import EmailThankYou from "./components/Contact/EmailThankYou";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Screen />} />
          <Route path="/collect" element={<EmailCollect />} />
          <Route exact path="/thankyou/:name" element={<Thankyou />} />
          <Route exact path="/collect/:name" element={<EmailThankYou />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
