import React from "react";
import HeaderStyle from "./css/SAMobile.module.css";

const SubTitle = () => {
  const contentH2 = HeaderStyle.contentH2;
  const headerContent = HeaderStyle.headerContent;

  return (
    <div className={headerContent}>
      <br />
      <h2 className={contentH2}>
        HERMAN TAYLOR <hr style={{ margin: ".5rem" }} />
        <em>The Leading Expert In Substance Abuse Recovery </em>
      </h2>
      <p>
        Herman Taylor Substance Abuse and Recovery expert calls for a
        compassionate approach toward addiction, whether in ourselves or in
        others. Herman has been in the forefront of providing substance abuse
        services for 31 years, in city agencies and non- profits. Hernan used
        his knowledge from the school of hard knocks, and his training as a
        LMSW, CASAC, and MPA, to serve. Herman believes that his source for
        addiction was linked to trauma, anger, and resentment.
        <br /> <br />
        In his book From Nothing to Almost Something a best-seller Herman draws
        on cutting-edge personal experiences and real-life stories to show that
        most addictions originate in trauma and the inability to cope with life
        stressors. Using his own breakthrough experiences, Herman has inspired
        audiences, classrooms, and clients for 31 years, teaching them to use
        the formula he’s learned to have their breakthroughs.
      </p>
      {/* <hr style={{ margin: ".5rem" }} /> */}
    </div>
  );
};

export default SubTitle;
