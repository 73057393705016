import React from "react";
import HeaderStyle from "./css/Header.module.css";
import TitleImg from "../../images/mobile-header-images/mobile-header-title.png";
import { Image } from "react-bootstrap";
import Navigation from "../ui/Nav";
import useMediaQuery from "../UseMediaQuery";

const Title = () => {
  const headerTitleContainer = HeaderStyle.headerTitleContainer;
  const headerTitle = HeaderStyle.headerTitle;
  const matchesMinWith768 = useMediaQuery("(min-width:768px)")

  return (
    <>
    <div className={headerTitleContainer}>
   { !matchesMinWith768 && <Navigation/>}
      <Image className={headerTitle} src={TitleImg} alt="Header Title" />
    </div>
    </>
  );
};

export default Title;
