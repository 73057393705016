import React from "react";
import HeaderStyle from "./css/SAMobile.module.css";
// import { Button } from "react-bootstrap";
import useMediaQuery from "../UseMediaQuery";
// import { Link } from "react-router-dom";
import videoStyle from "./css/SAMobile.module.css";
import video1 from "../../images/highlights/Understanding_Addiction.mp4";

const GalleryVideoContainer = () => {
  const headerBtn = HeaderStyle.headerBtn;
  const headerBtnContainer = HeaderStyle.headerBtnContainer;
  const videoPlayer = videoStyle.videoPlayer;
  return (
    <div className={headerBtnContainer}>
      <div className={headerBtn}>
        <video src={video1} controls autoPlay muted className={videoPlayer} />
      </div>
    </div>
  );
};

export default GalleryVideoContainer;
