import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useRef } from "react";
import Name from "./Form Fields/Name";
import Email from "./Form Fields/Email";
import SubmitButton from "./Form Fields/SubmitButton";
import ContactStyle from "./Contact.module.css";
import Nav from "../ui/Nav";
import emailjs from "@emailjs/browser";

const EmailCollect = () => {
  const formStyle = ContactStyle.formStyle;

  const navigate = useNavigate();
  const refForm = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rs46w18", //service id
        "template_9jfzafr", //template id
        // console.log("DATA",  refForm.current.name.value),
        refForm.current,
        "jj28rdNiF0gMcDv0E" //public key
      )
    .then(
      (result) => {
        //   alert("Message successfully sent!");
        navigate(`/collect/${refForm.current.name.value}`);
        // window.location.reload(false);
        console.log("result", result);
      },
      (error) => {
        alert("Failed to send the message, please try again");
        console.log(error.text);
      }
    );
  };
  return (
    <>
      <Nav />
      <Form
        id="contact"
        ref={refForm}
        onSubmit={sendEmail}
        className={formStyle}
        autoComplete="on"
      >
        <h1 style={{ color: "white" }}>
          Fill out your name and email to get Herman's free eBook on Vision
          Boards
        </h1>
        <Name />
        <Email />
        <SubmitButton />
      </Form>
    </>
  );
};
export default EmailCollect;
