import React, { useContext } from "react";
import HeaderStyle from "./css/SAMobile.module.css";
import SubTitle from "./SubTitle";
import GalleryContainer from "./GalleryContainer";
import SADesktop from "./SADesktop";
import useMediaQuery from "../UseMediaQuery";
import SubTitleA from "./SubTitleA";
import GalleryVideoContainer from "./GalleryVideoContainer";
import {
  Accordion,
  AccordionContext,
  Button,
  useAccordionButton,
} from "react-bootstrap";
import GalleryVideoContainer1 from "./GalleryVideoContainer1";

const SAMobileSideVideos = () => {
  const headerImgBack = HeaderStyle.headerImgBack;
  const gradient = HeaderStyle.gradient;
  const headerRow = HeaderStyle.headerRow;
  const fireBottom = HeaderStyle.fireBottom;
  const bkcolor = { color: "transparent" };
  const headContainer = HeaderStyle.headContainer;
  const headSection1 = HeaderStyle.headSection1;
  const matchesMinWith665 = useMediaQuery("(min-width:768px)");

  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div style={{ textAlign: "center" }}>
        <Button
          // className={seeMore}
          type="button"
          onClick={decoratedOnClick}
          style={{
            display: isCurrentEventKey ? "none" : "inline",
            backgroundColor: "white",
            color: "black",
            border: "none",
            margin: "0 2vw",
            width: "80%",
          }}
        >
          {children}
          {isCurrentEventKey ? "CLOSE" : "PLAY VIDEO"}
        </Button>
      </div>
    );
  };

  const videoSection = (
    <>
      <Accordion>
        <div
          style={{
            padding: "0 2rem",
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
          id="PasterSection"
        >
          <b>Overcoming Addiction </b>: <br />
          With Determination and Degrees
        </div>
        <ContextAwareToggle eventKey="0"></ContextAwareToggle>
        <Accordion.Collapse eventKey="0">
          <div style={{ padding: "0 2rem" }}>
            <GalleryVideoContainer1 />
          </div>
        </Accordion.Collapse>
      </Accordion>
    </>
  );

  return (
    <>
      {!matchesMinWith665 && (
        <>
          <div className={headContainer}>
            <div className={headSection1}>
              <div className={headerImgBack}>
                <div className={gradient}>
                  <div className={headerRow}>
                    <div style={bkcolor}></div>
                  </div>
                  {/* <SubTitle /> */}
                  <br />
                  <div>
                    <SubTitleA />
                    <GalleryContainer />
                  </div>
                  <div
                    style={{
                      padding: "0 2rem",
                      color: "white",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    <b>Understanding Addiction</b>: <br />
                    The Obsession Behind the First Hit
                    <GalleryVideoContainer />
                  </div>
                  <hr />
                  {videoSection}
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className={fireBottom}></div>
        </>
      )}
      {matchesMinWith665 && <SADesktop />}
    </>
  );
};

export default SAMobileSideVideos;
