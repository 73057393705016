import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Image } from "react-bootstrap";
import img1 from "../../images/highlights/1.jpg";
import img2 from "../../images/highlights/2.jpg";
import img2a from "../../images/highlights/2a.jpg";
import img3 from "../../images/highlights/3.jpg";
import img4 from "../../images/highlights/4.jpg";
import style from "./css/SAMobile.module.css";

// import { useMediaQuery } from "@mui/material";

const Gallery = () => {
  const galleryStyle = style.galleryStyle;
  // const matchMobile = useMediaQuery("(min-width: 725px)");
  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      showArrows={true}
      infiniteLoop={true}
      showIndicators={false}
      showStatus={false}
      animationHandler="fade"
      transitionTime="500"
      interval={2000}
    >
      <div>
        <Image src={img1} className={galleryStyle} />
      </div>
      <div>
        <Image src={img2} className={galleryStyle} />
      </div>
      <div>
        <Image src={img2a} className={galleryStyle} />
      </div>
      <div>
        <Image src={img3} className={galleryStyle} />
      </div>
      <div>
        <Image src={img4} className={galleryStyle} />
      </div>
    </Carousel>
  );
};
export default Gallery;
