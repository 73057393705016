import React from "react";
import PurchaseStyle from "./css/PurchaseStyle.module.css";
import { Col } from "react-bootstrap";
import BtnCs from "../ui/BtnCs";
import useMediaQuery from "../UseMediaQuery";

const PcDesktop = () => {
  const h1Style = PurchaseStyle.h1Style;
  const PurchaseCol = PurchaseStyle.PurchaseCol;
  const divBtn = PurchaseStyle.divBtn;
  const matchesMW800 = useMediaQuery("(min-width:600px)");
  const bookLink =
    "https://www.amazon.com/Nothing-Almost-Something-Herman-Taylor-ebook/dp/B0CPRNWF38/ref=sr_1_1?crid=3KU6N1Y4IMVGX&keywords=from+nothing+to+almost+something+by+herman+taylor&qid=1702570322&sprefix=from+nothing+to+almost+so%2Caps%2C94&sr=8-1";

  return (
    <>
      <div className={divBtn}>
        <BtnCs href={bookLink}>
          <h1 className={h1Style}>
            Purchase {!matchesMW800 && <br />} Your Copy from amazon
          </h1>
        </BtnCs>
      </div>
    </>
  );
};

export default PcDesktop;
