import React from "react";
import HeaderStyle from "./css/Header.module.css";
import SocialMediaSection from "./socailMediaSection";
import HeaderImage from "../../images/header-images//herman-header-image2.png";
import Title from "./Title";
import SubTitle from "./SubTitle";
import useMediaQuery from "../UseMediaQuery";

import { Button, Image } from "react-bootstrap";
import PurchaseBtn from "./PurchaseBtn";

const DesktopHeader = () => {
  const rowHeight = HeaderStyle.rowHeight;
  const subTitleCol = HeaderStyle.subTitleCol;
  const BottomRightTri = HeaderStyle.BottomRightTri;
  const HeadImgSize = HeaderStyle.HeadImgSize;
  const headContainer = HeaderStyle.headContainer;
  const headSection1 = HeaderStyle.headSection1;
  const imgContainer = HeaderStyle.imgContainer;
  const matchesMinWith665 = useMediaQuery("(min-width:765px)");

  return (
    <>
      <div className={headContainer}>
        <div className={headSection1}>
          <div className={rowHeight}></div>
          <div>
            <Title />
            <div className={subTitleCol}>
              <SocialMediaSection />
              <div>
                <SubTitle />
              </div>
              <PurchaseBtn />
            </div>
          </div>
        </div>
        {matchesMinWith665 && <div className={imgContainer}></div>}

        {!matchesMinWith665 && (
          <Image src={HeaderImage} className={HeadImgSize} />
        )}
      </div>
      <div>
        <div className={BottomRightTri}></div>
        <div></div>
      </div>
    </>
  );
};

export default DesktopHeader;
