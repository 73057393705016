import React from "react";
import HeaderStyle from "./css/SAMobile.module.css";
// import { Button } from "react-bootstrap";
import useMediaQuery from "../UseMediaQuery";
// import { Link } from "react-router-dom";
import Gallery from "./Gallery";

const GalleryContainer = () => {
  const mediaItems = [
    // { type: "video", src: "video1.mp4" },
    // { type: "video", src: "video2.mp4" },
  ];

  const headerBtn = HeaderStyle.headerBtn;
  const headerBtnContainer = HeaderStyle.headerBtnContainer;

  return (
    <div className={headerBtnContainer}>
      <div className={headerBtn}>
        <Gallery mediaItems={mediaItems} />
      </div>
    </div>
  );
};

export default GalleryContainer;
